<template>
    <b-container v-if="expense">
        <b-row>
            <b-col>
                <h3 class="mt-3">{{ expense.id ? '修改開支' : '建立開支' }}</h3>
            </b-col>
            <b-col v-if="expense.id">
                <b-button class="mt-3 float-right" variant="danger" @click="onDelete">刪除開支</b-button>
            </b-col>
        </b-row>

        <b-form class="mt-3" @submit.stop.prevent="onSubmit">
            <h5>內容</h5>
            <b-form-group label="分店">
                <b-form-select v-model="expense.shop" :options="shopOptions" required></b-form-select>
            </b-form-group>
            <b-form-group label="分類">
                <b-form-select v-model="expense.category" :options="categoryOptions" required></b-form-select>
            </b-form-group>
            <b-form-group label="描述">
                <b-form-input v-model="expense.description"></b-form-input>
            </b-form-group>

            <h5>日期</h5>
            <b-form-group>
                <b-form-datepicker v-model="paidAt" placeholder="請選擇日期" required></b-form-datepicker>
            </b-form-group>

            <h5>總計</h5>
            <b-form-group>
                <b-input-group prepend="$">
                    <b-form-input v-model.number="expense.grandTotal" type="number" step="0.1" required></b-form-input>
                </b-input-group>
            </b-form-group>

            <b-row>
                <b-col>
                    <b-form-group>
                        <b-button block @click="$router.go(-1)">返回</b-button>
                    </b-form-group>
                </b-col>
                <b-col>
                    <b-form-group>
                        <b-button type="submit" variant="primary" block>儲存</b-button>
                    </b-form-group>
                </b-col>
            </b-row>
        </b-form>
    </b-container>
</template>

<script>
import { mapState } from 'vuex'
import { Expense } from '@/models'
import moment from 'moment'

export default {
    data() {
        return {
            action: this.$route.params.action,
            expense: null,
            paidAt: null,
        }
    },

    async created() {
        this.expense = this.action == 'create' ? Expense() : await this.$store.dispatch('getDocument', { col: 'expenses', id: this.$route.params.id })
        if (!this.expense) return this.$router.push('/expenses')
        await this.$store.dispatch('bindCollectionByMerchant', { col: 'shops', merchantId: localStorage.merchantId })
        await this.$store.dispatch('bindCollectionByMerchant', { col: 'expenseCategories', merchantId: localStorage.merchantId })

        this.paidAt = moment(this.expense.paidAt).format('YYYY-MM-DD')
    },

    computed: {
        ...mapState({
            shops: state => state.shops,
            expenseCategories: state => state.expenseCategories,
        }),

        shopOptions() {
            if (!this.shops) return []
            return this.shops.map(shop => {
                return {
                    text: shop.name,
                    value: {
                        id: shop.id,
                        name: shop.name,
                    }
                }
            })
        },

        categoryOptions() {
            if (!this.expenseCategories) return []
            return this.expenseCategories.map(category => {
                return {
                    text: category.name,
                    value: {
                        id: category.id,
                        name: category.name,
                    }
                }
            })
        }
    },

    methods: {
        async onDelete() {
            const res = await this.$store.dispatch('deleteDocument', { col: 'expenses', id: this.expense.id })
            this.$root.$bvToast.toast(`刪除${res ? '成功' :'失敗'}`, { title: res ? '成功' :'失敗', variant: res ? 'success' : 'danger' })
            return this.$router.push('/expenses')
        },
        
        async onSubmit() {
            this.expense.merchantId = localStorage.merchantId
            this.expense.paidAt = Number(moment(this.paidAt).format('x'))

            const res = await this.$store.dispatch(this.action == 'create' ? 'createDocument' : 'updateDocument', { col: 'expenses', doc: this.expense })
            this.$root.$bvToast.toast(`${this.action == 'create' ? '新增' : '更新'}${res ? '成功' :'失敗'}`, { title: res ? '成功' :'失敗', variant: res ? 'success' : 'danger' })
            return this.$router.push('/expenses')
        }
    }
}
</script>